













































































































































.searchMain {
    background: #eeeeee;
    padding: 0.4rem 0.5rem;
    margin-top: 0.3rem;

    @media (max-width:767px) {
        margin-top: 0.3rem;
    }

    .title {
        color: #333333;
        font-size: 0.24rem;
        margin-bottom: 0.3rem;
    }

    .ivu-form {
        display: flex;
        flex-wrap: wrap;
    }

    .product {
        width: 10.7rem;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .submit {
        width: 1rem;
        margin-left: 0.3rem;

        @media (max-width: 767px) {
            width: 50%;
            margin-left: 25%;
        }

        .ivu-btn-primary {
            width: 1rem;
            height: 0.5rem;
            background-color: #333333;
            border-color: #333333;

            @media (max-width: 767px) {
                width: 100%;
                height: 0.5rem * 1.5;
            }
        }
    }

    .textListt {
        display: flex;
        flex-wrap: wrap;

        div {
            flex: 0 0 10rem;
            display: flex;
            flex-wrap: wrap;
        }

        @media (max-width: 767px) {
            margin-top: 0.2rem;
        }

        span {
            color: #727272;
            font-size: 0.24rem;
            margin-right: 0.6rem;
            margin-bottom: 0.1rem;
            cursor: pointer;

            @media (max-width: 767px) {
                margin-right: 0.4rem;
            }
        }
    }
}

.newsType {
    display: flex;
    justify-content: flex-end;
    margin: 0.6rem 0;

    img {
        width: 0.55rem;
        cursor: pointer;
    }
}

.newsList {
    margin-top: .3rem;

    .newsListMain {
        display: flex;

        @media (max-width: 767px) {
            display: block;
        }
    }

    .newLeft {
        width: 8.3rem;
        display: flex;

        @media (max-width: 767px) {
            width: 100%;
            height: 7.1rem;
            overflow: hidden;
        }

        .newLeftL {
            width: 5.05rem;
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
            padding: 0.46rem 0.23rem 0.15rem 0.5rem;
            height: 5rem;
            text-align: justify;

            @media (max-width: 767px) {
                width: 50%;
                height: 100%;
                padding-left: 0;
            }

            .title {
                color: #ff1932;
                font-size: 0.3rem;
                margin-bottom: 0.27rem;
                overflow: hidden;
                /*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 0.7rem;
                line-height: 1.2;
            }

            .content {
                color: #333333;
                font-size: 0.18rem;
                margin-bottom: 0.27rem;
                line-height: 1.7;
                overflow: hidden;
                /*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
                display: -webkit-box;
                -webkit-line-clamp: 9;
                -webkit-box-orient: vertical;
                height: 2.7rem;

                @media (max-width: 767px) {
                    height: 100%;
                    -webkit-line-clamp: 11;
                }
            }

            .more {
                color: #ff1932;
                font-size: 0.24rem;
            }
        }

        .newLeftR {
            width: 3.25rem;
            height: 5rem;

            @media (max-width: 767px) {
                width: 50%;
                height: 100%;
            }

            img {
                display: block;
            }
        }
    }

    .newRight {
        width: 4.2rem;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
        background: #ffffff;
        height: 5rem;
        padding: 0.46rem 0.29rem;
        margin-left: 0.48rem;

        @media (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-top: 0.5rem;
        }

        .title {
            color: #ff1932;
            font-size: 0.3rem;
            margin-bottom: 0.27rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &L {
            text-align: justify;
        }

        .content {
            color: #333333;
            font-size: 0.18rem;
            margin-bottom: 0.27rem;
            line-height: 1.7;
            overflow: hidden;
            /*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
            display: -webkit-box;
            -webkit-line-clamp: 11;
            -webkit-box-orient: vertical;
            height: 3.3rem;

            @media (max-width: 767px) {
                -webkit-line-clamp: 6;
                height: 3rem;
            }
        }
    }

    .itemList {
        margin-top: 0.6rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .newItem {
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
            width: 6.2rem;
            padding: 0.29rem 0.48rem;
            margin-bottom: 0.6rem;
            margin-right: 0.6rem;

            @media (max-width: 767px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 0.3rem;
            }

            .title {
                color: #ff1932;
                font-size: 0.3rem;
                margin-bottom: 0.17rem;
            }

            .time {
                color: #828282;
                font-size: 0.18rem;
                margin-bottom: 0.17rem;
            }

            .content {
                color: #333333;
                font-size: 0.2rem;
                overflow: hidden;
                /*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .newItem:nth-child(even) {
            margin-right: 0;
        }
    }
}

.newListList {
    .newListListItem {
        display: flex;
        border-bottom: 0.01rem solid #eeeeee;
        padding: 0.2rem 0;

        @media (max-width:767px) {
            display: block;
            padding: .3rem 0;
        }

        .newListListItemL {
            width: 3.6rem;
            height: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:767px) {
                width: 100%;
                height: auto;

            }

            img {
                max-width: 90%;
                max-height: 90%;
                display: block;
            }
        }

        .newListListItemR {
            width: 9.4rem;

            // height: 2.4rem;
            @media (max-width:767px) {
                width: 100%;
            }

            .title {
                color: #333333;
                font-size: 0.24rem;
                border-left: 0.07rem solid #ff1932;
                padding-left: 0.25rem;
                // margin-top: 0.18rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;

                @media (max-width:767px) {
					font-size: 0.38rem;
                    margin-top: 0;
                }
            }

            .time {
                color: #aaaaaa;
                font-size: 0.2rem;
                margin: 0.12rem 0;
                padding-left: 0.34rem;

                @media (max-width: 767px) {
                    margin: 0.2rem 0;
                }
            }

            .content {
                color: #333333;
                font-size: 0.2rem;
                padding-left: 0.34rem;
                overflow: hidden;
                /*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                @media (max-width: 767px) {
                    margin-top: 0.2rem;
                    -webkit-line-clamp: 2;
                }
            }
        }
    }

}

.tabFoot {
    text-align: center;
    border-top: 0;
}

.noData {

    text-align: center;
    margin: 0.4rem 0;

    img {
        width: 5rem;
    }
}

.red {
    color: red;
}

.newsList {
    ::v-deep .ivu-input {
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .ivu-form-item {
        @media (max-width: 767px) {
            margin-bottom: 0.3rem;
        }
    }

    ::v-deep .ivu-input-icon {
        line-height: 43px;
        font-size: 24px;

        @media (max-width: 767px) {
            line-height: 32px;
            font-size: 16px;
        }
    }
}

.count {
    color: #727272;
    font-size: 0.24rem;
    margin: 0.4rem 0;
    display: flex;
    justify-content: space-between;

    img {
        margin-left: 0.2rem;
        cursor: pointer;
        width: 0.3rem;

        @media (max-width:767px) {
            width: .5rem;
            margin-left: 0.5rem;
        }
    }
}

.lable {
    margin-top: 0.1rem;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.34rem;
}
